.clearable{
    position: relative;
    
  }
  .clearable__clear{
    position: absolute;
    right:0; top:4px;
    left: 300px;
    padding: 0 8px;
    font-style: normal;
    font-size: 1.5em;
    user-select: none;
    cursor: pointer;
  }