.big_text {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8rem 0;
  text-align: center;
}
.big_text h1 {
  font-size: 10rem;
  color: dimgray;

  transform: translatez(20rem);
}
.big_text p {
  letter-spacing: 1px;
}

.catalog_accesscode_image_banner {
  border: 2px solid #aeaeae;
  border-radius: 1rem;
  margin: auto;
  margin-top: 2rem;
  display: block;
  max-width: 300px;
  height: 300px;
  object-fit: fill;
  object-position: bottom;
}

.catalog_accesscode_container {
  min-height: 50vh;
  width: fit-content;
  margin: auto;
  min-width: 500px;
  background-image: url(../img//brand/banner_catalog.png);
  background-repeat: round;
}


@media (max-width: 768px) { 
  .catalog_accesscode_container {
  min-height: 50vh;
  width: fit-content;
  margin: auto;
  min-width: auto;
  background-image: url(../img//brand/banner_catalog.png);
  background-repeat: round;
}
 }