main { margin: auto; max-width: 960px; }

.table-container {
  margin: 10px;
  padding: 0;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

tr.expandable > td {
  /* box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, .2); */
  padding: 0;
}

tr.expandable > td > .inner {
  margin: 15px;
  width: 100%;
  overflow: hidden;
}


.scrollable tbody {
  display: grid;
  overflow: auto;
  width: 100%;
  height: calc(46vh - 40px);
}

.inner span {
  float: left;
  margin-right: 1rem;
}