.no_posts {
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .post_footer {
    position: static;
    bottom: 0px;
  }
  
  .post_error_loading {
    padding-top: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .page_title {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button{
    border: none;
    background: none;
  }